<template>
  <a-modal width="60%" v-model="visible" :footer="null" :title="'折扣额明细'" :maskClosable="false">
    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="时间">
            <DateRange :startTime.sync="searchData.startDate" :endTime.sync="searchData.endDate"></DateRange>
          </a-form-model-item>
        </a-col>

        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="交易方式">
            <a-select :default-value="1">
              <a-select-option :value="1"> 上期余额 </a-select-option>
              <a-select-option :value="2"> 新增-推广费 </a-select-option>
              <a-select-option :value="3"> 过期-返利 </a-select-option>
              <a-select-option :value="4"> 延期增加-返利 </a-select-option>
              <a-select-option :value="5"> 发货 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="单号">
            <a-input v-model="searchData.dealNo" placeholder="请输入单号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getList()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="page"
      :rowKey="(record) => record.id"
      bordered
      :maskClosable="false"
    >
      <span slot="JEFS" slot-scope="text">
        <span v-if="text == 1">上期余额</span>
        <span v-if="text == 2">新增-推广费</span>
        <span v-if="text == 3">过期-返利</span>
        <span v-if="text == 4">延期增加-返利</span>
        <span v-if="text == 5">发货-返利</span>
      </span>
      <span slot="DH" slot-scope="text">
        <span class="DH">{{ text }}</span>
      </span>
      <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
    </a-table>

    <div style="text-align: right; margin-top: 14px">
      <a-button type="default" @click="toCancel">关闭</a-button>
    </div>
  </a-modal>
</template>
<script>
export default {
  name: 'DealerBalanceDetailed',
  components: {},
  data() {
    return {
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      columns: [
        {
          title: '时间',
          dataIndex: 'SJ',
          align: 'center',
          key: 'SJ',
        },
        {
          title: '交易方式',
          dataIndex: 'JEFS',
          align: 'center',
          key: 'JEFS',
          scopedSlots: { customRender: 'JEFS' },
        },
        {
          title: '上期余额',
          dataIndex: 'SQYE',
          align: 'center',
          key: 'SQYE',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '增加',
          dataIndex: 'ZJ',
          align: 'center',
          key: 'ZJ',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '减少',
          dataIndex: 'JS',
          align: 'center',
          key: 'JS',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '结余',
          dataIndex: 'JY',
          align: 'center',
          key: 'JY',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '单号',
          dataIndex: 'DH',
          align: 'center',
          key: 'DH',
          scopedSlots: { customRender: 'DH' },
          width: 300,
        },
      ],
      visible: false,
      tableData: [],
      searchData: {}, // 搜索条件
      tableLoading: false, //表格loading
    }
  },
  mounted() {
    // this.getData()
  },
  methods: {
    init() {
      this.visible = true
      this.getData()
    },
    toCancel() {
      this.visible = false
    },
    getData() {
      this.tableData = [
        {
          SJ: '2022/06/01',
          JEFS: 1,
          SQYE: 10,
          CZ: 50,
          FH: 10,
          JY: 100,
          DH: 'SHSKD2022063015493562629',
        },
        {
          SJ: '2022/06/01',
          JEFS: 2,
          SQYE: 10,
          CZ: 50,
          FH: 10,
          JY: 100,
          DH: 'SHSKD2022063015493562629',
        },
        {
          SJ: '2022/06/01',
          JEFS: 3,
          SQYE: 10,
          CZ: 50,
          FH: 10,
          JY: 100,
          DH: 'SHSKD2022063015493562629',
        },
        {
          SJ: '2022/06/01',
          JEFS: 4,
          SQYE: 10,
          CZ: 50,
          FH: 10,
          JY: 100,
          DH: 'SHSKD2022063015493562629',
        },
        {
          SJ: '合计',
          SQYE: 10,
          CZ: 50,
          FH: 10,
          JY: 100,
        },
      ]
      // let params = {
      //   dealerId: this.dealerId,
      //   pageSize: this.page.pageSize,
      //   pageNumber: this.page.current,
      // }
      //   const postData = Object.assign(params, this.searchData)
      //   this.axios
      //     .get(
      //       `/api/dealer/dealer/dealerPayRecord/selectPayRecordByDealer?${qs.stringify(postData, {
      //         arrayFormat: 'repeat',
      //       })}`
      //     )
      //     .then((res) => {
      //       if (res.code === 200) {
      //         const { total, records } = res.body
      //         this.page.total = total
      //         this.tableData = records
      //       }
      //     })
    },
  },
}
</script>

<style lang="less" scoped>
.DH {
  color: rgb(27, 172, 229);
  cursor: pointer;
  text-decoration: underline;
}
</style>